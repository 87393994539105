<template>
    <div class="pagination">
        <span>Mostrar: </span>
        <div class="btn-group">
            <li @click="toggleMenu()" class="dropdown-toggle paddingNumberSelect" v-if="selectedOption !== undefined">
                {{ selectedOption }}
                <i class="material-icons caret paddingIconSelect">expand_more</i>
            </li>

            <ul class="dropdown-menu" v-if="showMenu">
                <li v-for="(option, idx) in perPage" :key="idx">
                    <a href="javascript:void(0)" @click="updateOption(option)">{{ option }}</a>
                </li>
            </ul>
        </div>

        <a href="javascript:void(0)" class="page">{{ from }} - {{ to }} de {{ total }}</a>

        <template v-if="!short">
            <ul :class="paginationClasses.ul">
                <li v-if="paginationLabels.first" @click="first" :class="`${paginationClasses.li} pagination-box ${hasFirst ? paginationClasses.liDisable : ''}`">
                    <i class="fa fa-chevron-left icon" aria-hidden="true"></i>
                </li>

                <li
                    v-for="page in items"
                    :key="page.label"
                    @click="goto(page)"
                    :class="`${paginationClasses.li} pagination-box ${page.active ? paginationClasses.liActive : ''} ${page.disable ? paginationClasses.liDisable : ''}`"
                >
                    <span
                        v-if="page.disable"
                        :class="`${paginationClasses.button} ${paginationClasses.buttonDisable}`"
                    >
                        ...
                    </span>
                    <button
                        v-else
                        :class="`${paginationClasses.button} ${page.active ? paginationClasses.buttonActive : ''}`"
                    >
                        {{ page.label }}
                    </button>
                </li>

                <li v-if="paginationLabels.last" @click="last" :class="`${paginationClasses.li} pagination-box ${hasLast ? paginationClasses.liDisable : ''}`">
                    <i class="fa fa-chevron-right icon" aria-hidden="true"></i>
                </li>
            </ul>
        </template>

        <template v-else>
            <ul :class="paginationClasses.ul">
                <li @click="prev" :class="`${paginationClasses.li} pagination-box ${linkPrev == null ? paginationClasses.liDisable : ''}`">
                    <i class="fa fa-chevron-left icon" aria-hidden="true"></i>
                </li>

                <li @click="next" :class="`${paginationClasses.li} pagination-box ${linkNext == null ? paginationClasses.liDisable : ''}`">
                    <i class="fa fa-chevron-right icon" aria-hidden="true"></i>
                </li>
            </ul>
        </template>
    </div>
</template>

<script>
const defaultClasses = {
    ul: '',
    li: 'page',
    liActive: 'page-active',
    liDisable: 'disabled',
    button: 'pagination-link',
    buttonActive: 'pagination-link--active',
    buttonDisable: 'disabled'
}

const defaultLabels = {
    first: '&laquo;',
    prev: '&lsaquo;',
    next: '&rsaquo;',
    last: '&raquo;'
}

export default {
    props: {
        from: {
            type: Number,
            default: 0,
            required: true
        },
        to: {
            type: Number,
            default: 0,
            required: true
        },
        total: {
            type: Number,
            default: 0,
            required: true
        },
        currentPage: {
            type: Number,
            default: 1,
        },
        lastPage: {
            type: Number,
            default: 1,
        },
        linkFirst: {
            type: String,
            default: '/',
        },
        linkPrev: {
            type: String,
            default: '/',
        },
        linkNext: {
            type: String,
            default: '/'
        },
        linkLast: {
            type: String,
            default: '/',
        },
        tableData: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        perPage: {
            type: Array,
            default: () => ([]),
            required: true,
        },
        classes: {
            type: Object,
            required: false,
            default: () => ({})
        },
        labels: {
            type: Object,
            required: false,
            default: () => ({})
        },
        short: {
            type: [Boolean],
            default: false,
        },
        closeOnOutsideClick: {
            type: [Boolean],
            default: true,
        },
    },
    data() {
        return {
            selectedOption: null,
            showMenu: false,
            pagination: {
                from: this.min,
                to: this.max,
                totalRecord: this.total
            },
            links: {
                prev: this.linkPrev,
                next: this.linkNext
            },
            paginationClasses: {
                ...defaultClasses,
                ...this.classes
            },
            paginationLabels: {
                ...defaultLabels,
                ...this.labels
            }
        }
    },
    computed: {
        items() {
            let valPrev = this.currentPage > 1 ? (this.currentPage - 1) : 1 // for easier navigation - gives one previous page
            let valNext = this.currentPage < this.lastPage ? (this.currentPage + 1) : this.lastPage // one next page
            let extraPrev = valPrev === 3 ? 2 : null
            let extraNext = valNext === (this.lastPage - 2) ? (this.lastPage - 1) : null
            let dotsBefore = valPrev > 3 ? 2 : null
            let dotsAfter = valNext < (this.lastPage - 2) ? (this.lastPage - 1) : null

            let output = []

            for (let i = 1; i <= this.lastPage; i += 1) {
                if ([1, this.lastPage, this.currentPage, valPrev, valNext, extraPrev, extraNext, dotsBefore, dotsAfter].includes(i)) {
                        output.push({
                        label: i,
                        active: this.currentPage === i,
                        disable: [dotsBefore, dotsAfter].includes(i)
                    })
                }
            }

            return output
        },

        hasFirst() {
            return (this.currentPage === 1)
        },

        hasLast() {
            return (this.currentPage === this.lastPage)
        },
    },
    methods: {
        first() {
            if (!this.hasFirst) {
                this.$emit('getPageAction', this.linkFirst);
            }
        },
        prev() {
            if(!this.hasFirst) {
                if(this.linkPrev != null){
                    this.$emit('getPageAction', this.linkPrev);
                }
            }
        },
        goto(page) {
            if(this.linkFirst != "/" && !page.disable) {
                if(this.linkFirst.includes('page=')){
                    let urlSplit = this.linkFirst.split('page=');
                    this.$emit('getPageAction', urlSplit[0] + "page=" + page.label);
                }
            }
        },
        next() {
            if (!this.hasLast) {
                if(this.linkNext != null){
                    this.$emit('getPageAction', this.linkNext);
                }
            }
        },
        last() {
            if (!this.hasLast) {
                this.$emit('getPageAction', this.linkLast);
            }
        },

        updateOption(option) {
            this.tableData.items_to_show    = this.selectedOption = option;
            this.showMenu                   = false;
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        clickHandler(event) {
            const { target } = event;
            const { $el } = this;
            if(!$el.contains(target)) {
                this.showMenu = false;
            }
        },
    },
    beforeDestroy() {
        document.removeEventListener('click', this.clickHandler);
    },
    mounted() {
        this.selectedOption = this.tableData.items_to_show;

        if (this.closeOnOutsideClick) {
            document.addEventListener('click', this.clickHandler);
        }
    }
}
</script>

<style scoped>
    .pagination {
        padding: 20px;
        display: inline-block;
        
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #828282;
    }
    .page {
        display: inline;
        padding: 0px 9px;
        text-decoration: none;
        text-align: left;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #828282;
    }

    ul {
        display: inline-block;
        padding: 0;
        margin: 0;
    }
    ul li {
        display: inline;
    }

    li.pagination-box {
        padding: 10px 12px;
        transition: background-color .3s;
        border: 1px solid #C4C4C4;
        cursor: pointer;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;

        color: #828282;
    }

    li.pagination-box button {
        outline: 0;
    }

    li.pagination-box:first-child {
        border-radius: 8px 0 0 8px;
    }

    li.pagination-box:last-child {
        border-radius: 0 8px 8px 0;
    }

    li.pagination-box.page-active {
        background-color: #bdcceb;
    }

    .pagination-box > .icon {
        color: #828282;
        font-size: 12px !important;
    }

    .page.disabled {
        opacity: 0.65; 
        cursor: not-allowed;
    }

    .btn-group {
        min-width: 80px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }
    .btn-group a:hover {
        text-decoration: none;
    }
    .dropdown-toggle {
        border: 0.5px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 9px 45px 8px 15px;
        width: 80px !important;
        height: 40px !important;
        outline: 0;

        color: #828282;
        min-width: 80px;
        background-color: #FFFFFF;
        transition: background 0s ease-out;
        float: none;
        box-shadow: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .dropdown-toggle:hover {
        background: #fff;
        cursor: pointer;
    }
    .dropdown-menu {
        position: absolute;
        top: -145px;
        left: 0;
        z-index: 1000;
        float: left;
        min-width: 100px;
        padding: 5px 0;
        margin: 2px 0 0;
        list-style: none;
        font-size: 14px;
        text-align: left;
        background-color: #FFF;
        border: 1px solid #ECEEEF;
        border-radius: 4px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        background-clip: padding-box;
    }
    .dropdown-menu > li > a {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #828282;

        padding: 10px 30px;
        display: block;
        clear: both;
        white-space: nowrap;
        text-decoration: none;
    }
    .dropdown-menu > li > a:hover {
        background: #efefef;
    }
    .dropdown-menu > li {
        overflow: hidden;
        width: 100%;
        position: relative;
        margin: 0;
    }
    .caret {
        color: #828282;
        font-size: 18px;
        position: absolute;
        top: 7px;
        margin-left: -24px;
        vertical-align: middle;
        right: 5px;
    }
    li {
        list-style: none;
    }
    .paddingNumberSelect{
        padding-top:14px;
    }   
    .paddingIconSelect{
        padding-top:4px

    }
</style>