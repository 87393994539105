<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa' && rol != 'admin-sucursal'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container grid-list-xl fluid v-else>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0 pl-0">
                    <div class="pt-2" style="display: flex; width:100%; height:100%; justify-content: flex-start;">
                        <hr class="vr" />
                        <p class="tblName py-2">Asignación de empleado a dispositivo biométrico</p>
                    </div>
                </v-col>
                <v-col v-if="rol != 'admin-depto'" cols="12" xs="12" sm="12" lg="12" xl="12" class="pb-0 pl-0">
                    <v-card class="borde-card pa-6 mr-3" elevation="0">
                        <v-row class="pa-0 ma-0">
                            <v-col v-if="rol =='root'" cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
                                <v-autocomplete
                                    outlined
                                    label="Cliente"
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    v-model="cliente_value"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol =='admin'" cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
                                <v-autocomplete
                                    outlined
                                    label="Empresa"
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    v-model="empresa_value"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol =='admin' || rol=='admin-empresa' " cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
                                <v-autocomplete
                                    outlined
                                    label="Sucursal"
                                    :items="sucursales"
                                    item-text="nombre"
                                    item-value="id"
                                    v-model="sucursal_value"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
                                <v-autocomplete
                                    outlined
                                    label="Departamento"
                                    :items="departamentos"
                                    item-text="nombre"
                                    item-value="id"
                                    v-model="departamento_value"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col  cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex justify-end">
                                <v-btn
                                    class="btn-primario"
                                    @click="borrarCampos()" 
                                >
                                    Borrar campos
                                </v-btn>
                                <v-btn
                                    class="btnGuardar ml-2"
                                    @click="filtrar()" 
                                >
                                    Filtrar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col sm="12" md="6" class="pl-0">
                    <data-table-checkbox
                        ref="tableEmpleados"
                        :url="url"
                        @selectAllEvent="getSelected"
                        :columns="columns"
                        :filters="filters"
                        :perPage="[10,25,50,100]"
                        shortPagination
                        :idTabla="'tabla1'"
                    >
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td class="mb-4">
                                    <v-checkbox class="pb-5 pl-5" primary hide-details :value="item.id" v-model="selected"></v-checkbox>
                                </td> 
                                <td class="tblPrincipal px-0"> {{ item.nombre + ' ' + item.apellido_paterno + ' ' + item.apellido_materno }} </td>
                                <td class="tblPrincipal align-center justify-center text-center"> 
                                    <div>{{ item.clave }} </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table-checkbox>
                </v-col>

                <v-col sm="12" md="6" :style="((rol != 'root' && rol != 'admin')|| btnAsignar) ? 'padding-top: 12px;' : 'padding-top: 65px;'">
                    <div class="botonAgregar d-flex justify-end" style="padding-right: 12px;">
                        <v-btn rounded dark class="btnAdd mb-0 mt-4" @click="agregar()" :loading="loading_guardar" v-if="(rol != 'root' && rol != 'admin') || btnAsignar ">
                            Asignar
                        </v-btn>
                    </div>
                    <v-col>
                        <data-table-checkbox
                            ref="tableChecador"
                            :url="url_checador"
                            @selectAllEvent="getSelected_checador"
                            :columns="columns_checador"
                            :filters="filters_checador"
                            :perPage="[10,25,50,100]"
                            :showSearchbox="false"
                            :idTabla="'tabla2'"
                        >
                            <tbody slot="body" slot-scope="{ data }">
                                <tr :key="item.id" v-for="item in data">
                                    <td>
                                        <v-checkbox class="mb-5 pl-5" primary hide-details :value="item.id" v-model="selected_checador"></v-checkbox>
                                    </td> 
                                    <td class="px-0"> 
                                        <v-list two-line class="transparente">
                                            <v-list-item two-line class="pl-0">
                                                <v-list-item-content>
                                                    <v-list-item-title class="tblPrincipal">{{ item.descripcion }}</v-list-item-title>
                                                    <v-list-item-subtitle class="pa-0 tblSecundario">{{item.nombre_sucursal}} - {{item.nombre_departamento}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </td>
                                    <td class="centrarTexto"> {{ item.serial }} </td>
                                </tr>
                            </tbody>
                        </data-table-checkbox>
                    </v-col>
                </v-col>
            </v-row>
            <template>
                <v-dialog v-model="dialogErrores" persistent max-width="950px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalErrores"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="padding_izquierdo padding_derecho padding_arriba">
                                <v-btn
                                    @click="cerrarModalErrores()"
                                    small
                                    slot="activator"
                                    icon
                                    text
                                    class="v-btn-cerrar modal-pull-right"
                                    absolute
                                    top
                                    right
                                >
                                    <v-icon class="icono-cerrar"></v-icon>
                                </v-btn>
                            <div class="headerModalStart">
                                <h2 class="titleModalErrores">Dispositivos con error</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="padding_izquierdo padding_derecho padding_abajo">
                            
                            <v-container grid-list-md>
                                <v-row class="pa-0 ma-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                        <table class="tbl-errores">
                                            <thead class="tbl-header">
                                                <tr>
                                                    <th style="border-top-left-radius: 20px;" class="pl-7 errorth">Nombre</th>
                                                    <th style="border-top-right-radius: 20px;" class="errorth">IP</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="errores.length == 0" >
                                                    <td class="emptyTableErrores" colspan="3">
                                                        No hay elementos para mostrar
                                                    </td>
                                                </tr>
                                                <template v-else v-for="(error, index) in errores">
                                                    <template>
                                                        <tr :key="index">
                                                            <td class="pl-7 errortd pt-4 pb-0">
                                                                <div class="textoTablaError01">{{error.nombre}}</div>
                                                            </td>
                                                            <td class="errortd"> <div class="textoTablaError01">{{error.ip}}</div></td>
                                                        </tr>
                                                        
                                                        <tr :key="'A'+index" class="trDivider"><div class="my-2"></div></tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>
import dispositivosApi from '../api/dispositivos';
import empleadosApi from '../api/empleados';
import Notify from '../plugins/notify';
import Datatable from '@/components/datatableCheckbox/Datatable.vue';
import apiClientes from '@/api/clientes';
import apiEmpresas from '@/api/empresas';
import apiSucursales from '@/api/sucursales';
import apiDepartamentos from '@/api/departamentos';

export default {
    components: {
        'data-table-checkbox': Datatable,
    },
    data() {
        return {
            url: "empleados/buscar",
            url_checador:"reloj-checador/find",
            columns_checador: [
                {
                    label: 'Dispositivo',
                    name: 'nombre',
                    align: 'left',
                },
                {
                    label: 'Número de serie',
                    name: 'serial',
                    align: 'center',
                },
            ],
            columns: [
                {
                    label: 'Empleado',
                    name: 'descripcion',
                    align: 'left',
                },
                {
                    label: 'Clave empleado',
                    name: 'clave',
                    align: 'center',
                },
            ],
            filters: {
                activo          : true,
                paginate        : true,
                include_padres  : true,
                cliente_id      : null,
                empresa_id      : null,
                sucursal_id     : null,
                departamento_id : null,
            },
            filters_checador: {
                activo          : true,
                paginate        : true,
                include_padres  : true,
                cliente_id      : null,
                empresa_id      : null,
                sucursal_id     : null,
                departamento_id : null,
                tipo            : "fisico"
            },
            selected: [],
            selected_checador: [],
            loadingChecador : true,
            dialog          : false,
            datosLogin      : '',
            complex:{
                selected:[],
                headers:[
                    { text: "Nombre", value: "nombre" },
                    { text: "Clave Empleado",value: "clave_empleado" }
                ],
                items:[]
            },
            checador:{
                selected:[],
                headers:[
                    { text: "Nombre", value: "nombre", sortable: false,class:'cabecera-tabla'},
                    { text: "Número de serie",value: "serial", sortable: false, class:'cabecera-tabla-der' },
                ],
                items:[],
                show: 100
            },
            loading_guardar : false,
            checador_php    : [],
            empleados_php   : [],
            rol             : "",
            dialogErrores   : false,
            errores         : [],
            clientes        : [],
            empresas        : [],
            sucursales      : [],
            departamentos   : [],
            cliente_value   : null,
            empresa_value   : null,
            sucursal_value  : null,
            departamento_value: null,
            isLoadig        : false,
            btnAsignar      : false,

        }
    },
    watch: {
        cliente_value: function(val) {
            if(val != null){
                this.getEmpresas(val);
            }
        },
        empresa_value: function(val) {
            if(val != null){
                this.getSucursales(val);
            }
        },
        sucursal_value: function(val){
            if(val != null){
                this.getDepartamentos(val);
            }
        },
    },
    methods: {
        listar() {
            this.loadingChecador    = true;
            this.datosLogin         = this.$session.get('usuario');
            this.rol                = this.datosLogin.rol.name;
            let empresa             = null;
            let sucursal            = null;
            let cliente             = null;

            if(this.rol == 'root') {
                this.getClientes();
            }
            else if(this.rol == "admin") {
                cliente  = this.filters.cliente_id = this.filters_checador.cliente_id = this.datosLogin.cliente_id;
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else if(this.rol == "admin-empresa") {
                empresa  = this.filters.empresa_id = this.filters_checador.empresa_id = this.datosLogin.empresa_id;
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            else if(this.rol == "admin-sucursal") {
                sucursal  = this.filters.sucursal_id = this.filters_checador.sucursal_id = this.datosLogin.sucursal_id;
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
            }
            else{
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
            }
            

            let param = { 
                            activo          : true,
                            paginate        : false,
                            include_padres  : true,
                            empresa_id      : empresa,
                            sucursal_id     : sucursal,
                            cliente_id      : cliente
                        };

            dispositivosApi.getDispositivos(param).then((response) => {
                let formateado      = response.data;
                this.checador.items = [];

                formateado.forEach(element => {
                let temporal              = new Object();
                temporal.id               = element['id'];
                temporal.ubicacion        = element['nombre_departamento'] + " - " + element['nombre_sucursal'];
                temporal.nombre           = element['descripcion'];
                temporal.ip               = element['ip'];
                temporal.serial           = element['serial'];
                temporal.empresa_id       = element["empresa_id"];
                temporal.sucursal_id      = element["sucursal_id"];
                temporal.departamento_id  = element["departamento_id"];
                this.checador.items.push(temporal);
                });
                this.loadingChecador = false;
            })
            .catch(err => {
                Notify.Error("Se presentó un error", err.response.data.error);
            });
        },
        getSelected(isSelected, items) {
            this.selected = items;
        },
        getSelected_checador(isSelected, items) {
            this.selected_checador = items;
        },
        agregar() {
            let session               = this.$session.get('usuario');
            let tableData             = this.$refs.tableEmpleados.data.data;
            let tableDataChecador     = this.$refs.tableChecador.data.data;
            this.rol                  = session.rol.name;
            this.loading_guardar      = true;


            if(this.selected.length == 0 || this.selected_checador.length == 0 ) {
                this.loading_guardar      = false;

                Notify.Warning("Mensaje", "Es requerido seleccionar mínimo un empleado y un checador.");
                return;
            }

            let data                  = new Object();
            data.checadores           = [];
            data.empleados            = [];


            let arrEmpleados = tableData.filter(item => this.selected.includes(item.id));

            arrEmpleados.forEach(element => {
                let temporal            = new Object();
                let empleado            = element['nombre'] + " " + element['apellido_paterno'] + " " + element['apellido_materno'];
                temporal.id             = element['id'];
                temporal.clave_empleado = String(element['clave']);
                temporal.nombre         = empleado.substr(0,14);
                temporal.permiso        = 'user';
                data.empleados.push(temporal)
            });

            let arrChecador = tableDataChecador.filter(item => this.selected_checador.includes(item.id));
            arrChecador.forEach(element => {
                let temporal        = new Object();
                temporal.id         = element['id'];
                temporal.nombre     = element['descripcion'];
                temporal.ip         = element['ip'];
                data.checadores.push(temporal)
            });

            if(this.rol != "root" && this.rol != "admin") {
                data.empresa_id = this.datosLogin.empresa_id;
            }

            if(this.rol == "root" || this.rol == "admin"){
                if(this.empresa_value != null){
                    data.empresa_id = this.empresa_value
                }
                else{
                    return;
                }
            }

            empleadosApi.asignacionEmpleado(data).then((response) => {
                if(response.status == 200){
                    this.finaliza();
                    Notify.Success("Operación exitosa", "El registro de empleados se realizó correctamente");
                }
                else if(response.status == 202){
                    
                    this.errores = response.data.error;
                    this.dialogErrores = true;
                    
                    this.finaliza();
                }

                this.selected = [];
                this.selected_checador = [];
                this.$refs.tableEmpleados.unselectAll = !this.$refs.tableEmpleados.unselectAll;
                this.$refs.tableChecador.unselectAll = !this.$refs.tableChecador.unselectAll;
            })
            .catch(err => {
                this.finaliza();
                Notify.Error("Se presentó un error", err.response.data.error);
            });
        },
        finaliza() {
            this.loadingEmpleado    = false;
            this.loadingChecador    = false;
            this.complex.selected   = [];
            this.checador.selected  = [];
            this.loading_guardar    = false;
            this.listar();
        },
        redireccionar(){
            this.$router.push('/dashboard');
        },
        cerrarModalErrores(){
            this.dialogErrores = false;
            this.errores = [];
        },
        getClientes(){
            let parametros = {
                activo      : true,
                paginate    : false,
                order       : 'asc',
                order_by    : 'nombre'
            }
            
            apiClientes.getClientes(parametros).then((response) => {
                this.clientes = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },
        getEmpresas(){
            let parametros = {
                activo      : true,
                paginate    : false,
                order       : 'asc',
                order_by    : 'nombre',
                cliente_id  : this.cliente_value
            }
            apiEmpresas.getEmpresas(parametros).then((response) => {
                this.empresas = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },
        getSucursales(){
            let parametros = {
                activo      : true,
                paginate    : false,
                order       : 'asc',
                order_by    : 'nombre',
                cliente_id  : this.cliente_value,
                empresa_id  : this.empresa_value
            }
            apiSucursales.getSucursales(parametros).then((response) => {
                this.sucursales = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },
        getDepartamentos(){
            let parametros = {
                activo      : true,
                paginate    : false,
                order       : 'asc',
                order_by    : 'nombre',
                cliente_id  : this.cliente_value,
                empresa_id  : this.empresa_value,
                sucursal_id : this.sucursal_value,
            }

            apiDepartamentos.getDepartamentos(parametros).then((response) => {
                this.departamentos = response.data;
            })
            .catch((err) => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los departamentos.");
            })
        },

        filtrar(){
            if(this.rol == 'root') {
                this.filters.cliente_id = this.filters_checador.cliente_id = this.cliente_value;
                this.filters.empresa_id = this.filters_checador.empresa_id = this.empresa_value;
                this.filters.sucursal_id = this.filters_checador.sucursal_id = this.sucursal_value;
                this.filters.departamento_id = this.filters_checador.departamento_id = this.departamento_value;

                if(this.empresa_value != null && this.empresa_value != '' ){
                    this.btnAsignar = true;
                }
                else{
                    this.btnAsignar = false;
                }
            }
            else if(this.rol == "admin") {
                this.filters.cliente_id = this.filters_checador.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.filters_checador.empresa_id = this.empresa_value;
                this.filters.sucursal_id = this.filters_checador.sucursal_id = this.sucursal_value;
                this.filters.departamento_id = this.filters_checador.departamento_id = this.departamento_value;

                if(this.empresa_value != null && this.empresa_value != ''){
                    this.btnAsignar = true;
                }
                else{
                    this.btnAsignar = false;
                }
            }
            else if(this.rol == "admin-empresa") {
                this.filters.cliente_id = this.filters_checador.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.filters_checador.empresa_id = this.datosLogin.empresa_id;
                this.filters.sucursal_id = this.filters_checador.sucursal_id = this.sucursal_value;
                this.filters.departamento_id = this.filters_checador.departamento_id = this.departamento_value;
            }
            else if(this.rol == "admin-sucursal") {
                this.filters.cliente_id = this.filters_checador.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.filters_checador.empresa_id = this.datosLogin.empresa_id;
                this.filters.sucursal_id = this.filters_checador.sucursal_id = this.datosLogin.sucursal_id;
                this.filters.departamento_id = this.filters_checador.departamento_id = this.departamento_value;
            }
            else{
                this.filters.cliente_id = this.filters_checador.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.filters_checador.empresa_id = this.datosLogin.empresa_id;
                this.filters.sucursal_id = this.filters_checador.sucursal_id = this.datosLogin.sucursal_id;
                this.filters.departamento_id = this.filters_checador.departamento_id = this.datosLogin.departamento_id;
            }

            this.$refs.tableEmpleados.getData();
            this.$refs.tableChecador.getData();

        },
        borrarCampos(){
            if(this.rol == 'root') {
                this.cliente_value = null;
                this.empresa_value = null;
                this.sucursal_value = null;
                this.departamento_value = null;
                this.btnAsignar = false;
            }
            else if(this.rol == "admin") {
                this.empresa_value = null;
                this.sucursal_value = null;
                this.departamento_value = null;
                this.btnAsignar = false;
            }
            else if(this.rol == "admin-empresa") {
                this.sucursal_value = null;
                this.departamento_value = null;
            }
            else if(this.rol == "admin-sucursal") {
                this.departamento_value = null;
            }
        }
    },
    created() {
        this.datosLogin         = this.$session.get('usuario');
        this.rol                = this.datosLogin.rol.name;
        this.listar();
    },
    mounted() {
        document.querySelector('#tabla1 thead tr th:nth-child(2)').style.paddingRight   = "0px";
        document.querySelector('#tabla1 thead tr th:nth-child(2)').style.paddingLeft    = "0px";
        document.querySelector('#tabla2 thead tr th:nth-child(2)').style.paddingRight   = "0px";
        document.querySelector('#tabla2 thead tr th:nth-child(2)').style.paddingLeft    = "0px";
    },
    updated() {
        document.querySelector('#tabla1 thead tr th:nth-child(2)').style.paddingRight   = "0px";
        document.querySelector('#tabla1 thead tr th:nth-child(2)').style.paddingLeft    = "0px";
        document.querySelector('#tabla2 thead tr th:nth-child(2)').style.paddingRight   = "0px";
        document.querySelector('#tabla2 thead tr th:nth-child(2)').style.paddingLeft    = "0px";
    },
}
</script>

<style scoped>
    .transparente{
        background-color: transparent;
        padding: 0 0;
    }
    .centrarTexto{
        text-align: center;
    }
    .btnAdd.theme--dark.v-btn, .theme--light.v-btn {
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 12px !important;
        line-height: 15px !important;
        color: #FFFFFF !important;
        background: #1E2245;
        text-transform:capitalize !important;
    }
    .titleModalErrores{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }
    .emptyTableErrores{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    .tbl-errores {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-errores .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .errorth {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        line-height: 20px;
        padding: 8px;
        text-align: left !important;
    }
    .errortd {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #96999A;
        line-height: 15px;
        padding: 8px;
        text-align: left !important;
    }
    .textoTablaError01{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
        text-align: left !important;
    }
    .textoTablaError02{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #828282;
        text-align: left !important;
    }
    .textoTablaError03{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #EB5757;
        line-height: 17px;
        text-align: left !important;
    }
    .trDivider{
        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #C4C4C4;
    }
    #btnModalErrores{
        display: none;
    }
</style>