import Vue from 'vue';

export default {
    getDispositivos(parametros){
        return Vue.axios.get("reloj-checador/find", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getEmpleados(parametros){
        return Vue.axios.get("reloj-checador/empleados", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getDispositivo(id){
        return Vue.axios.get("reloj-checador/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateDispositivo(parametros){
        return Vue.axios.put("reloj-checador", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteDispositivo(id){
        return Vue.axios.delete("reloj-checador/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addDispositivo(parametros){
        return Vue.axios.post("reloj-checador", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    dropDispositivo(id){
        return Vue.axios.delete("reloj-checador/drop/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    dropEmpleado(parametros){
        return Vue.axios.post("reloj-checador/remover-empleado", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    eliminarDispositivo(parametros){
        return Vue.axios.post("reloj-checador/delete", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

}