<template>
  <div :class="tableContainerClasses">
    <table :class="['ck-table', tableClasses]" :id="idTabla">
      <thead :class="tableHeaderClasses">
        <tr :class="tableRowClasses">
          <th class="py-1" style="width:150px">
            <v-checkbox class="pl-5 my-1" hide-details v-model="selectAll"></v-checkbox>
          </th>
          <th
            scope="col"
            :key="column.name"
            v-for="column in columns"
            :class="headerClasses(column)"
            :style="'width: ' + column.width + '%'"
            @click="column.filterable ? sort(column) : null"
          >
            <div class="align-center" :style="`display: flex; justify-content: ${column.align == null? 'left' : column.align}; margin-top:auto; margin-bottom:auto;`">
              {{ column.label }}
              <template v-if="column.filterable">
                <div
                  class="filter align-center pt-1"
                  v-if="
                    column.filterable &&
                      column.name == currentSort &&
                      dir == 'asc'
                  "
                >
                  <i class="material-icons">expand_less</i>
                </div>
                <div class="filter align-center pt-1" v-else>
                  <i class="material-icons">expand_more</i>
                </div>
              </template>
            </div>
          </th>
        </tr>
      </thead>
      <slot> </slot>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentSort: "",
      selectAll: false,
    };
  },
  watch: {
    selectAll: {
      handler: function(newValue) {
        this.$emit("selectAll", this.selectAll);
      },
    },
  },
  props: {
    idTabla: {
        type: String,
        default: "tableScrollSimple",
    },
    dir: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default: () => [],
      required: true,
    },
    sortKey: {
      type: String,
      default: "",
    },
    sortOrders: {
      type: Object,
      default: () => ({}),
    },
    tableClasses: {
      type: Object,
      default: () => ({}),
    },
    tableHeaderClasses: {
      type: Object,
      default: () => ({
        "p-3": true,
        "text-left": true,
      }),
    },
    tableRowClasses: {
      type: Object,
      default: () => ({}),
    },
    tableContainerClasses: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    headerClasses(column) {
      let classes = this.tableHeaderClasses;
      classes["table-header-sorting"] = column.filterable;
      return classes;
    },
    sort(column) {
      this.currentSort = column.name;
      this.$emit("sort", column.name, column.columnName);
    },
  },
};
</script>

<style scoped>
  .filter {
    color: #96999a;
    cursor: pointer;
  }
  .inline-block {
    display: inline-block;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    /* white-space: nowrap; */
    overflow-x: scroll;
    overflow-y: scroll;

    box-shadow: 4px 4px 8px rgba(196, 196, 196, 0.2);
  }
  th {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: #828282;
    background-color: #f6f5f6;
    padding: 13px 45px 13px 45px;
  }
  th > div{
    max-width: 15rem;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}
  td {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    color: #1E2245;
    background-color: #fdfcfc;
    padding: 13px 45px 13px 45px;
    letter-spacing: -0.34px;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    max-width: 15rem;
  }
  td > div{
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      max-width: 15rem;
      margin-right: auto;
      margin-left: auto;
  }

  table.ck-table thead {
    height: 66px;
  }
  table.ck-table thead tr th:first-child {
    border-top-left-radius: 20px !important;
  }
  table.ck-table thead tr th:last-child {
    border-top-right-radius: 20px;
  }
</style>
