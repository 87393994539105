<template>
    <div>
        <template v-if="enableModal">
            <div class="display-row pull-right">
                <v-btn title="Buscar" class="search-icon" icon @click="openModal()">
                    <v-icon >search</v-icon>
                </v-btn>
            </div>
            <slot name="actionButtons" v-if="actionButtonsSlot"></slot>
        </template>

        <template v-else>
            <v-row v-if="showSearchbox">
                <v-col xs="12" sm="12" md="12" class="mb-4">
                    <!-- <div class="searchbox">
                        <input id="buscador" class="search__input" :class="{'search__icon' : search.trim() == ''}" type="text" v-model="search" placeholder="Buscar empleado" />
                    </div> -->
                    <v-text-field
                        class="py-0 my-0 mr-1"
                        v-model="search"
                        placeholder="Buscar empleado"
                        @keyup.enter="buscador()"
                        solo
                        flat
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <!-- <div v-if="showSearchbox" class="display-row" style="padding-left: 15px; padding-right: 15px; padding-top: 10px;">
            </div> -->
        </template>


        <vue-table
            ref="vueTable"
            @sort="sortBy"
            @selectAll="selectAll"
            :sortKey="sortKey"
            :columns="columns"
            :idTabla="idTabla"
            :sortOrders="sortOrders"
            :table-classes="classes.table"
            :table-header-classes="classes['t-head']"
            :table-row-classes="classes['t-head-tr']"
            :table-container-classes="classes['table-container']"
            >
            <template v-if="bodySlot">
                <template v-if="isLoading">
                    <tbody>
                        <tr>
                            <td :colspan="columns.length + 1">
                                <v-progress-linear :active="isLoading" color="blue" indeterminate></v-progress-linear>
                            </td>
                        </tr>
                    </tbody>
                </template>

                <template v-else>
                    <slot
                        name="body"
                        v-if="bodySlot"
                        :data="data.data">
                    </slot>
                </template>
            </template>

            <template v-else>
                <tbody
                    v-if="!! columns"
                    :class="classes['t-body']">
                    <tr
                        :key="item.id"
                        v-for="item in data.data"
                        :class="classes['t-body-tr']">
                        <td
                            :key="column.name"
                            :class="classes.td"
                            v-for="column in columns">
                            <data-table-cell
                                :value="item"
                                :name="column.name"
                                :meta="column.meta"
                                :event="column.event"
                                :comp="column.component"
                                :classes="column.classes"
                                :handler="column.handler">
                            </data-table-cell>
                        </td>
                    </tr>
                </tbody>
            </template>
        </vue-table>

        <div v-if="showPagination" class="display-row pagination-container">
            <div>
                <slot
                    :meta="data.meta"
                    name="pagination"
                    :links="data.links"
                    v-if="paginationSlot">
                </slot>
                <data-table-pagination 
                    v-else
                    @getPageAction="getPageAction"
                    :short="shortPagination"
                    :from="data.from != null ? data.from : 0"
                    :to="data.to != null ? data.to : 0"
                    :total="data.total"
                    :currentPage="data.current_page"
                    :lastPage="data.last_page"
                    :linkFirst="data.first_page_url"
                    :linkPrev="data.prev_page_url"
                    :linkNext="data.next_page_url"
                    :linkLast="data.last_page_url"
                    :perPage="perPage"
                    :tableData="tableData">
                </data-table-pagination>
            </div>
        </div>

        <template>
            <v-row justify-center>
                <v-dialog v-model="dialogFilters" persistent max-width="700px">
                    <v-btn slot="activator" class="botonModaltheme botonModal" id="btnBusquedaAvanzada"></v-btn>
                    <v-card>
                        <v-card-title>
                            <span class="headline" v-text="modalTitle"></span>
                        </v-card-title>
                        <v-card-text @keyup.enter="filter()">
                            <v-container grid-list-md id="contenedor">
                                <v-row wrap>
                                    <slot
                                        name="filters"
                                        v-if="filtersSlot">
                                    </slot>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" flat @click="closeModal()">Cerrar</v-btn>
                            <v-btn color="blue darken-1" flat @click="filter()">Buscar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row> 
        </template>
    </div>

</template>

<script>
import Vue from 'vue';
import _ from 'lodash';

import UrlFilters from './mixins/UrlFilters';
import VueTable from './Table.vue';
import DataTableCell from './DatatableCell.vue';
import DataTableFilters from './DatatableFilters.vue';
import DataTablePagination from './DatatablePagination.vue';

export default {
    created() {
        if(this.addFiltersToUrl) {
            this.checkParameters(this.tableData);
        } else {
            this.getData();
        }

        this.debouncedGetSearch = _.debounce(this.getSearch, 500)
    },
    mounted() {
        this.columns.forEach((column) => {
            this.sortOrders[column.name] = -1;
        })
    },
    mixins: [UrlFilters],
    watch: {
        filters: {
            handler: function() {
                this.getData();
            }
        },
        url: {
            handler: function(newUrl) {
                this.getData(newUrl);
            },
        },
        tableData: {
            handler: function() {
                this.getData();
            },
            deep: true,
        },
        search: function(newSearch, oldSearch) {
            this.debouncedGetSearch();
        },
        unselectAll: function(newValue) {
            this.$refs.vueTable.selectAll = false;
        }
    },
    components: {
        'vue-table'             : VueTable,
        'data-table-cell'       : DataTableCell,
        'data-table-filters'    : DataTableFilters,
        'data-table-pagination' : DataTablePagination,
    },
    data() {
        return {
            data            : {},
            search          : "",
            selectedItems   : [],
            unselectAll     : false,
            isLoading       : false,
            sortKey         : 'id',
            sortOrders      : {},
            tableData       : {
                paginate        : true,
                items_to_show   : this.perPage[0],
            },
            dialogFilters   : false,
        }
    },
    props: {
        idTabla: {
            type: String,
            default: "tableScrollSimple",
        },
        url: {
            type: String,
            default: "/",
            required: true,
        },
        orderBy: {
            type: String,
            default: 'id',
        },
        enableModal: {
            type: Boolean,
            default: false,
        },
        showPagination: {
            type: Boolean,
            default: true
        },
        showSearchbox: {
            type: Boolean,
            default: true
        },
        filters: {
            type: Object,
            default: () => ({}),
        },
        modalTitle: {
            type: String,
            default: 'Busqueda avanzada'
        },
        addFiltersToUrl: {
            type: Boolean,
            default: false,
        },
        columns: {
            type: Array,
            default: () => ([]),
            required: true,
        },
        pagination: {
            type: Object,
            default: () => ({
                limit: 1,
                align: 'right',
            }),
        },
        perPage: {
            type: Array,
            default: () => ([
                '10',
                '25',
                '50'
            ]),
        },
        orderDir: {
            type: String,
            default: "asc",
            validator: function (value) {
                return [
                    'asc',
                    'desc'
                ].indexOf(value) !== -1;
            }
        },
        shortPagination: {
            type: [Boolean],
            default: false,
        },
        classes: {
            type: Object,
            default: () => ({
                'table-container': {
                    'table-responsive': true,
                },
                'table': {
                    'table': true,
                    'table-striped': true,
                    'table-dark': true,
                },
                't-head': {},
                't-body': {},
                'td': {},
                'th': {},
            }),
        },
        selected: {
            type: Array,
            default: () => ([]),
        },
    },
    methods: {
        getData(url = this.url) {
            this.isLoading = true;
            url = this.checkUrlForPagination(url);
            //this.incrementDraw();
            Vue.axios.get(url, this.getRequestPayload)
                .then(response => {
                    if (response) {
                        let data = response.data;
                        //if (this.checkTableDraw(data.payload.draw)) {
                            this.isLoading = false;
                            this.data = data;
                            if (this.addFiltersToUrl) {
                                this.updateParameters(this.tableData);
                            }
                        //}
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    alert(errors);
                });
        },
        getSearch() {
            this.getData();
        },
        sortBy(key, columnName = null) {
            this.sortKey            = key;
            this.sortOrders[key]    = this.sortOrders[key] * -1;
            //this.tableData.column   = columnName ? columnName : key;
            //this.tableData.dir      = this.sortOrders[key] === 1 ? 'desc' : 'asc';
        },
        selectAll(value) {
            if(value) {
                for (let i in this.data.data) {
                    this.selectedItems.push(this.data.data[i].id);
                }
            } else {
                this.selectedItems = []
            }

            this.$emit('selectAllEvent', value, this.selectedItems);
        },
        getIndex(array, key, value) {
            return array.findIndex(i => i[key] == value);
        },
        incrementDraw() {
            this.draw++;
        },
        checkTableDraw(draw) {
            if (this.draw == draw) {
                return true;
            }
            return false;
        },
        checkUrlForPagination(url) {
            if (Number.isInteger(url)) {
                url = this.url + "?page=" + url;
                return url;
            }
            return url;
        },
        getPageAction (value) {
            this.url = value;
        },
        openModal(){
            this.$emit('loadModalData');
            setTimeout(function(){ document.getElementById("btnBusquedaAvanzada").click(); }, 100);
        },
        closeModal(){
            this.dialogFilters = false;
        },
        filter() {
            this.$emit('setFilters');
            this.closeModal();
        },
    },
    computed: {
        paginationSlot() {
            if (this.$scopedSlots) {
                return this.$scopedSlots.pagination;
            }
            return null;
        },
        filtersSlot() {
            if (this.$scopedSlots) {
                return this.$scopedSlots.filters;
            }
            return null;
        },
        bodySlot() {
            if (this.$scopedSlots) {
                return this.$scopedSlots.body;
            }
            return null;
        },
        actionButtonsSlot() {
            if (this.$scopedSlots) {
                return this.$scopedSlots.actionButtons;
            }
            return null;
        },
        getRequestPayload() {
            let payload     = Object.assign({}, this.tableData);
            delete payload.filters;
            //payload         = Object.assign(payload, this.tableData.filters);
            //payload.draw    = this.draw;

            payload         = Object.assign(payload, this.filters);
            payload.palabra = this.search.trim();

            return {
                params: payload
            }
        }
    }
}
</script>

<style scoped>
    .searchbox {
        width: 100%;
    }

    .search__input {
        width: 100%;
        padding: 12px 24px;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: rgba(150, 153, 154, 0.7);
 
        background-color: #FFF;
        border-radius: 20px;
        transition: all 250ms ease-in-out;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        transition: transform 250ms ease-in-out;
    }
    .search__input:focus {
        outline: 0;
    }
    .search__input::placeholder:focus {
        padding-left: 0px;
    }
    .search__input::placeholder {
        color: rgba(150, 153, 154, 0.7);
        padding-left: 20px;
    }
    .search__input.search__icon {
        background-image: url("/static/icon/searchbox.png");
        background-repeat: no-repeat;
        background-size: 18px 18px;
        background-position: 2% center;
    }

    .display-row {
        /* display: inline-block; */
        text-align: center;
    }
    .display-row.pagination-container {
        width: 100%;
        text-align: right;
        background-color: #fdfcfc;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        padding: 0px 43px;
        padding-top: 20px;

        box-shadow: 4px 4px 8px rgba(196, 196, 196, 0.2);
    }
    .display-row.pagination-container > div {
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: #c4c4c4;
    }
    .pull-right {
        text-align: right !important;
    }

    #contenedor{
        margin-top: -6%;
    }

    .search-icon {
        background-color: #F8FCFF;
        border: 1px solid #D3D7EB;
        border-radius: 100%;
        box-shadow: 0 2px 12px 0 rgba(129, 158, 200, 0.06);
        width: 30px;
        height: 30px;
    }
    .search-icon-color {
        color: #d4d8ec !important;
    }
    .search-icon-color:hover {
        color: #bec2d4 !important;
    }
    .botonModaltheme.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .botonModal.theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
</style>